<template>
  <div>
    <section class="section head">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half-desktop">
            <img src="../assets/girl.svg" alt="" class="girl" />
            <img src="../assets/boy.svg" alt="" class="boy" />
            <h1>Milou &amp; Kaz</h1>
            <h2>2-4 september 2022</h2>
          </div>
        </div>
      </div>
    </section>
    <section class="section main">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-half-desktop">
            <div class="content">
              <Form :naam="naam" />
              <!--<p class="has-text-centered">
                Je kunt het formulier niet meer invullen.
              </p>-->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Form from '@/components/Form.vue'

export default {
  data() {
    return {
      naam: this.$route.query.naam
    }
  },
  components: {
    Form
  },
  mounted() {
    // nix
  }
}
</script>
