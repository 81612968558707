<template>
  <form @submit="sendForm" id="form">
    <fieldset v-bind="{ disabled: isLoading }">
      <div class="columns">
        <div class="column is-full">
          <div class="field">
            <label class="label">Wie is erbij?</label>
            <div class="control has-icons-left">
              <input
                class="input"
                type="text"
                placeholder=""
                :value="naam"
                name="naam"
                required
              />
              <span class="icon is-small is-left">
                <i class="fas fa-users"></i>
              </span>
            </div>
            <p class="help is-danger"></p>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <div class="title is-6 label">Welke dagen?</div>
          <div class="field">
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="optie"
                  value="Hele weekend"
                  required
                />&nbsp;&nbsp;Hele weekend (vrijdagavond t/m zondagochtend)
              </label>
            </div>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="optie"
                  value="Alleen zaterdag"
                />&nbsp;&nbsp;Alleen zaterdag
              </label>
            </div>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="optie"
                  value="Alleen zaterdag &amp; zondag"
                />&nbsp;&nbsp;Alleen zaterdag &amp; zondag
              </label>
            </div>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="optie"
                  value="Alleen vrijdagavond &amp; zaterdag"
                />&nbsp;&nbsp;Alleen vrijdagavond &amp; zaterdag
              </label>
            </div>
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  name="optie"
                  value="Geen idee"
                />&nbsp;&nbsp;Geen idee
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Opmerkingen</label>
            <div class="control">
              <textarea
                class="textarea"
                placeholder=""
                name="opmerkingen"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Wie is leuker?*</label>
            <div class="control">
              <div class="select">
                <select required name="voorkeur">
                  <option></option>
                  <option>Milou</option>
                  <option>Kaz</option>
                  <option>Lily</option>
                  <option>Coco</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="control">
              <button
                type="submit"
                class="button is-primary"
                v-bind:class="{ 'is-loading': isLoading }"
              >
                Verzenden
              </button>
            </div>
          </div>
        </div>
      </div>
      <p class="is-size-6">* Denk goed na over je antwoord!</p>
    </fieldset>
  </form>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    naam: String
  },
  data() {
    return {
      isLoading: false
    }
  },
  methods: {
    sendForm: function(e) {
      e.preventDefault()
      let $this = this
      this.isLoading = true

      const formData = new FormData(document.getElementById('form'))

      axios({
        method: 'post',
        url: 'https://api.mgntrn.nl/save-the-date', //http://localhost:8000/save-the-date.php //https://api.mgntrn.nl/save-the-date
        data: formData
      }).then(function(response) {
        if (response.data.status == 'success') {
          $this.$router.push({ path: '/bedankt' })
        } else {
          if (response.data.message) {
            alert(response.data.message)
          }

          $this.isLoading = false
        }
      })
    }
  }
}
</script>
